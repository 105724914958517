var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{attrs:{"max-width":"650px"}},[_c('v-card-title',[(_vm.isEditView && _vm.booking.crm_boat)?_c('span',{staticClass:"headline"},[_vm._v(" Edit booking ")]):_c('span',{staticClass:"headline",attrs:{"data-cy":"itemAddNewText"}},[_vm._v("Add new booking")])]),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('v-text-field',{attrs:{"label":"Guest email*","color":"primary","data-cy":"itemSkuInput","autocomplete":"new-password","readonly":_vm.isEditView,"required":"","rules":_vm.guestRule},model:{value:(_vm.booking.guest_email),callback:function ($$v) {_vm.$set(_vm.booking, "guest_email", $$v)},expression:"booking.guest_email"}})],1),(!_vm.isEditView)?_c('v-col',{attrs:{"cols":"12"}},[(!_vm.loading)?_c('date-range-picker',{attrs:{"initial-data":_vm.isEditView ? _vm.booking.date_from : _vm.$moment().toISOString(),"min-date":_vm.$moment().toISOString(),"min-time":undefined,"label":'Date from'},on:{"set":_vm.handleDateFrom,"unset":_vm.handleDateFrom}}):_vm._e()],1):_vm._e(),(!_vm.isEditView)?_c('v-col',{attrs:{"cols":"12"}},[(!_vm.loading)?_c('date-range-picker',{attrs:{"initial-data":_vm.isEditView
                    ? _vm.booking.date_to
                    : _vm.$moment()
                        .add(7, 'days')
                        .toISOString(),"min-date":_vm.booking.date_to.length !== 0
                    ? _vm.$moment(_vm.booking.date_from).toISOString()
                    : _vm.$moment().toISOString(),"label":'Date to'},on:{"set":_vm.handleDateTo,"unset":_vm.handleDateTo}}):_vm._e()],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('boat-selector',{attrs:{"initial-boat":_vm.booking.crm_boat,"show-boat-name":true,"rules":_vm.boatRule},on:{"change":_vm.handleBoatSelection}})],1),(_vm.booking.crm_boat && _vm.booking.checkin_checklist)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pt-0 pb-0 mt-0 mb-0 pl-0",attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"pl-0 pt-0 pb-0"},[_vm._v(" Tasks: ")]),_c('v-card-text',{staticClass:"pl-0 pb-0 pr-0"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('tasks-list',{attrs:{"title":_vm.title,"headers":_vm.headers,"data":_vm.data,"loading":_vm.loading,"bookings":true},on:{"details":_vm.previewFn}})],1)],1)],1)],1)],1):_vm._e(),(
                _vm.booking.crm_boat &&
                  _vm.allCheckinChecklists.length > 0 &&
                  !_vm.booking.checkin_checklist &&
                  !_vm.isEditView
              )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pt-0 pb-0 mt-0 mb-0 pl-0",attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"pl-0"},[_vm._v(" To enable contactless checkin, select appropiate checklists for this boat. "),_c('br'),_vm._v(" Guests will receive these checklists by email 24 hours prior to checkin. ")]),_c('v-card-text',{staticClass:"pl-0"},_vm._l((_vm.allCheckinChecklists),function(bookingChecklist,index){return _c('div',{key:index},[_c('v-checkbox',{attrs:{"color":"primary","label":bookingChecklist.checklists[0].name,"value":bookingChecklist.uuid},model:{value:(_vm.bookingCheckinChecklists),callback:function ($$v) {_vm.bookingCheckinChecklists=$$v},expression:"bookingCheckinChecklists"}})],1)}),0)],1)],1):_vm._e(),(
                _vm.booking.crm_boat &&
                  _vm.allCheckoutChecklists.length > 0 &&
                  !_vm.booking.checkout_checklist &&
                  !_vm.isEditView
              )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pt-0 pb-0 mt-0 mb-0 pl-0",attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"pl-0"},[_vm._v(" Select Checklists for Guest Checkout "),_c('br'),_vm._v(" Guests will be notified 24 hours prior to checkout. ")]),_c('v-card-text',{staticClass:"pl-0"},_vm._l((_vm.allCheckoutChecklists),function(bookingChecklist,index){return _c('div',{key:index},[_c('v-checkbox',{attrs:{"color":"primary","label":bookingChecklist.checklists[0].name,"value":bookingChecklist.uuid},model:{value:(_vm.bookingCheckoutChecklists),callback:function ($$v) {_vm.bookingCheckoutChecklists=$$v},expression:"bookingCheckoutChecklists"}})],1)}),0)],1)],1):_vm._e()],1)],1)],1),_c('v-card-actions',[(_vm.isEditView)?_c('v-btn',{attrs:{"color":"red","data-cy":"itemCancelBtn","text":""},on:{"click":function($event){return _vm.remove()}}},[_vm._v("Delete ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","data-cy":"itemCancelBtn","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v("Cancel ")]),_c('v-btn',{attrs:{"color":"primary","data-cy":"itemSaveBtn","text":""},on:{"click":function($event){return _vm.save(false)}}},[_vm._v("Save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
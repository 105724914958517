<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
    color="primary"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="dateRangeText"
        :label="label"
        readonly
        color="primary"
        @focus="openPicker = true"
        v-on="on"
        :hint="hint"
      ></v-text-field>
    </template>
    <v-card color="primary" tile flat background-color="primary">
      <v-tabs
        tile
        flat
        v-model="tab"
        align-with-title
        background-color="primary"
        dark
      >
        <v-tabs-slider dark></v-tabs-slider>
        <v-tab>
          Date
        </v-tab>
        <v-tab>
          Time
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-date-picker
            style="border-radius: 0 !important"
            v-if="openPicker"
            v-model="date"
            color="primary"
            :min="minDate"
            @input="changeTab"
          ></v-date-picker>
        </v-tab-item>
        <v-tab-item>
          <v-time-picker
            style="border-radius: 0 !important"
            v-model="time"
            :min="minTime"
            format="24hr"
          ></v-time-picker>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-card tile flat>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn text color="primary" @click="close">Close</v-btn> -->
        <v-btn text color="primary" @click="success">Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  props: {
    initialData: {
      type: String,
      default: String,
    },
    minDate: {
      type: String,
      default: String,
    },
    label: {
      type: String,
      default: "Date",
    },
    hint: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    tab: 0,
    menu: null,
    openPicker: false,
    date: "",
    time: "12:00",
  }),
  created() {
    if (!!this.initialData && this.initialData.length !== 0) {
      [this.date, this.time] = this.$moment(this.initialData)
        .format("YYYY-MM-DD HH:mm")
        .split(" ");
    }
    const minTimeDate = this.minDate;
    this.minTime = this.$moment(minTimeDate).format("hh:mm:ss a");
  },
  computed: {
    dateRangeText() {
      return !!this.date && !!this.time ? `${this.date} ${this.time}` : null;
    },
  },
  methods: {
    changeTab(val) {
      if (val === null) {
        return;
      }
      this.tab = 1;
    },
    success() {
      let minutes = this.time.split(":")[1];
      let hours = this.time.split(":")[0];

      let date = this.$moment(this.date);

      date.add(minutes, "minutes").add(hours, "hours");

      this.$emit("set", date);
      this.openPicker = false;
      this.menu = false;
    },
  },
};
</script>
<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal;
}
</style>

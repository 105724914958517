<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card max-width="650px">
        <v-card-title>
          <span class="headline" v-if="isEditView && booking.crm_boat">
            Edit booking
            <!-- {{ booking.crm_boat.boat_name }} booking -->
          </span>
          <span class="headline" data-cy="itemAddNewText" v-else
            >Add new booking</span
          >
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  v-model="booking.guest_email"
                  label="Guest email*"
                  color="primary"
                  data-cy="itemSkuInput"
                  autocomplete="new-password"
                  :readonly="isEditView"
                  required
                  :rules="guestRule"
                />
              </v-col>

              <v-col cols="12" v-if="!isEditView">
                <date-range-picker
                  v-if="!loading"
                  :initial-data="
                    isEditView ? booking.date_from : $moment().toISOString()
                  "
                  @set="handleDateFrom"
                  @unset="handleDateFrom"
                  :min-date="$moment().toISOString()"
                  :min-time="undefined"
                  :label="'Date from'"
                />
              </v-col>
              <v-col cols="12" v-if="!isEditView">
                <date-range-picker
                  v-if="!loading"
                  :initial-data="
                    isEditView
                      ? booking.date_to
                      : $moment()
                          .add(7, 'days')
                          .toISOString()
                  "
                  @set="handleDateTo"
                  @unset="handleDateTo"
                  :min-date="
                    booking.date_to.length !== 0
                      ? $moment(booking.date_from).toISOString()
                      : $moment().toISOString()
                  "
                  :label="'Date to'"
                />
              </v-col>
              <v-col cols="12">
                <boat-selector
                  :initial-boat="booking.crm_boat"
                  :show-boat-name="true"
                  @change="handleBoatSelection"
                  :rules="boatRule"
                ></boat-selector>
              </v-col>

              <v-col
                cols="12"
                v-if="booking.crm_boat && booking.checkin_checklist"
              >
                <v-card class="pt-0 pb-0 mt-0 mb-0 pl-0" elevation="0">
                  <v-card-text class="pl-0 pt-0 pb-0">
                    Tasks:
                  </v-card-text>

                  <v-card-text class="pl-0 pb-0 pr-0">
                    <v-row dense>
                      <v-col cols="12">
                        <tasks-list
                          :title="title"
                          :headers="headers"
                          :data="data"
                          :loading="loading"
                          :bookings="true"
                          @details="previewFn"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col
                cols="12"
                v-if="
                  booking.crm_boat &&
                    allCheckinChecklists.length > 0 &&
                    !booking.checkin_checklist &&
                    !isEditView
                "
              >
                <v-card class="pt-0 pb-0 mt-0 mb-0 pl-0" elevation="0">
                  <v-card-text class="pl-0">
                    To enable contactless checkin, select appropiate checklists
                    for this boat. <br />
                    Guests will receive these checklists by email 24 hours prior
                    to checkin.
                  </v-card-text>

                  <v-card-text class="pl-0">
                    <div
                      v-for="(bookingChecklist, index) in allCheckinChecklists"
                      :key="index"
                    >
                      <v-checkbox
                        color="primary"
                        :label="bookingChecklist.checklists[0].name"
                        v-model="bookingCheckinChecklists"
                        :value="bookingChecklist.uuid"
                      ></v-checkbox>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col
                cols="12"
                v-if="
                  booking.crm_boat &&
                    allCheckoutChecklists.length > 0 &&
                    !booking.checkout_checklist &&
                    !isEditView
                "
              >
                <v-card class="pt-0 pb-0 mt-0 mb-0 pl-0" elevation="0">
                  <v-card-text class="pl-0">
                    Select Checklists for Guest Checkout <br />
                    Guests will be notified 24 hours prior to checkout.
                  </v-card-text>

                  <v-card-text class="pl-0">
                    <div
                      v-for="(bookingChecklist, index) in allCheckoutChecklists"
                      :key="index"
                    >
                      <v-checkbox
                        color="primary"
                        :label="bookingChecklist.checklists[0].name"
                        v-model="bookingCheckoutChecklists"
                        :value="bookingChecklist.uuid"
                      ></v-checkbox>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red"
            data-cy="itemCancelBtn"
            v-if="isEditView"
            text
            @click="remove()"
            >Delete
          </v-btn>
          <v-spacer />
          <v-btn color="primary" data-cy="itemCancelBtn" text @click="close()"
            >Cancel
          </v-btn>
          <v-btn color="primary" data-cy="itemSaveBtn" text @click="save(false)"
            >Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import BoatSelector from "@/components/tasks/BoatSelector";
import api from "@/utils/api.js";
import DateRangePicker from "@/components/tasks/DateRangePicker";
import TasksList from "@/components/lists/TasksList.vue";
import snackbar from "@/utils/snackbar.js";
export default {
  components: { DateRangePicker, BoatSelector, TasksList },
  props: {
    bookingUuid: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    dialog: {},
    valid: true,
    booking: {
      date_from: "",
      date_to: "",
      guest_email: null,
      boat: null,
      crm_boat: null,
      send_checkin_checklist_uuids: [],
      send_checkout_checklist_uuids: [],
    },
    guestRule: [
      (v) => !!v || "Guest email is required",
      (v) => /.+@.+\..+/.test(v) || "Email must be valid",
    ],
    boatRule: [(v) => !!v || "Boat is required"],
    showBookingDialog: false,
    isEditView: null,
    loading: false,
    bookingChecklists: [],
    bookingCheckinChecklists: [],
    bookingCheckoutChecklists: [],
    allCheckinChecklists: [],
    allCheckoutChecklists: [],
    bookingChecklistsTypes: [],
    headers: [
      {
        text: "Title",
        align: "left",
        value: "name",
      },
      {
        text: "Assigned to",
        align: "left",
        value: "assignee",
      },
      {
        text: "Due date",
        align: "left",
        value: "due_date",
      },
      {
        text: "Status",
        align: "left",
        value: "status",
      },
    ],
    data: [],
    sort: "name",
    title: "Tasks",
  }),
  created() {
    this.booking.date_from = this.$moment().toISOString();
    this.booking.date_to = this.$moment()
      .add(7, "days")
      .toISOString();
    this.isEditView = !!this.bookingUuid;
    if (this.bookingUuid) {
      this.loadBooking(this.bookingUuid);
    }
  },
  methods: {
    previewFn(task) {
      this.$router.push({
        name: "TasksDetails",
        params: { id: task.uuid },
      });
    },
    handleDateFrom(date) {
      this.booking.date_from = this.$moment(date).toISOString();
    },

    handleDateTo(date) {
      this.booking.date_to = this.$moment(date).toISOString();
    },
    save() {
      if (this.$refs.form.validate()) {
        this.booking.crm_boat_uuid = this.booking.crm_boat.uuid;
        this.booking.send_checkin_checklist_uuids = this.bookingCheckinChecklists;
        this.booking.send_checkout_checklist_uuids = this.bookingCheckoutChecklists;
        if (this.isEditView) {
          api.updateBooking(this.booking.uuid, this.booking).then((resp) => {
            this.$emit("saved");
            this.close();
          });
        } else {
          api.createBooking(this.booking).then((resp) => {
            this.$emit("saved");
            this.close();
          });
        }
      } else {
        snackbar.error("Please fill required fields");
      }
    },
    remove() {
      api.removeBooking(this.bookingUuid).then((resp) => {
        this.$emit("remove", true);
        this.close();
      });
    },
    close() {
      this.$emit("update:show-dialog", false);
      // this.$emit("saved");
    },
    handleBoatSelection(selection) {
      this.booking.crm_boat = selection;
      if (selection !== null) this.loadChecklists();
    },

    loadChecklists() {
      api
        .getBookingChecklists({
          crm_boat_uuid: this.booking.crm_boat.uuid,
          include_guest_checklists: true,
        })
        .then((resp) => {
          this.bookingChecklists = resp.data;
          this.allCheckinChecklists = this.bookingChecklists.filter(
            (el) => el.checklists[0].checklist_type == "GUEST_CHECKIN"
          );
          this.allCheckoutChecklists = this.bookingChecklists.filter(
            (el) => el.checklists[0].checklist_type == "GUEST_CHECKOUT"
          );
        });
    },

    openItemDialog() {
      this.showBookingDialog = true;
    },
    openTask(taskUuid) {
      this.$router.push({
        name: "TasksDetails",
        params: { id: taskUuid },
      });
    },
    loadBooking(uuid) {
      this.loading = true;

      api.getBooking(uuid).then((resp) => {
        this.booking = resp.data;
        this.data = resp.data.tasks;

        if (this.booking.checkin_checklist) {
          this.bookingChecklistsTypes.push("GUEST_CHECKIN");
        }

        if (this.booking.checkout_checklist) {
          this.bookingChecklistsTypes.push("GUEST_CHECKOUT");
        }
        this.loading = false;
      });
    },
  },
};
</script>

<style></style>
